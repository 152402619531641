<template>
  <div>
    <ed-movimento :intCabecalhoMovimentoId="0" :intMovimentoId="0" :intTipoMovimentoId="1444" :key="$root.$session.versao+'1444'"/>
  </div>
</template>

<script>
import EdMovimento from "@/components/movimento/index";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {EdMovimento},
  mounted() {
  },
  created() {},
  data() {
    return {
    };
  },
  methods: {
  
  },
};
</script>